<template>
  <select :aria-label="$t('accessibility.language_switcher')"
          class="bg-theme-font text-white"
          :class="large ? 'py-1' : ''"
          v-if="languages.length > 1"
          @change="setLocale($event)"
          v-model="locale">
    <option v-for="language in languages"
            :value="language">
      {{ language.toUpperCase() }}
    </option>
  </select>
</template>
<script setup lang="ts">
defineProps<{
  large?: boolean
}>()
const platformSettings = usePlatformSettings();
const languages = platformSettings.availableLocales();
const locale = platformSettings.locale
const auth = useAuth();

function setLocale(event: Event) {
  const lang = (event.target as HTMLSelectElement).value.toLowerCase();
  platformSettings.setLocale(lang);

  if (auth.authenticated.value) {
    auth.updateLocale(lang);
  }
}

</script>
<style lang="scss" scoped>
select {
  @apply rounded-[7px] pl-2 pr-3 pt-1 pb-1 appearance-none bg-[url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")] bg-no-repeat;
  background-position: right 1rem top 50%;
  background-size: 0.65rem auto;
}
</style>